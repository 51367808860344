import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Layout from '../components/layout';
export const _frontmatter = {
  "title": "Vision"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Vision`}</h1>
    <p>{`Though Blocks is currently in its early stages, we have a lot
of ambition for this project. We're also looking for contributors
to help shape its future, so please
`}<a parentName="p" {...{
        "href": "https://github.com/blocks"
      }}>{`get involved on GitHub`}</a>{`!`}</p>
    <p><strong parentName="p">{`We want to make working on the web more visual`}</strong>{`. The Blocks
alpha is our first step towards trying to achieve that.`}</p>
    <h2>{`Blocks JSX editing format specification`}</h2>
    <p>{`We see Blocks as a universally applicable library for visually
editing JSX. We want other tools in the design and development
space to leverage this as a library (at any layer of its architecture)
and help us build a robust, community solution.`}</p>
    <h3>{`Sharing implementation details amongst products`}</h3>
    <p>{`If you're building a product that can leverage Blocks we welcome
that! We didn't set out to create "yet another page builder", but
we couldn't find anything that `}<strong parentName="p">{`read in production JSX`}</strong>{`.`}</p>
    <p>{`There's a long road ahead to support more and more variations of
JSX. We should solve this together.`}</p>
    <h2>{`Built with accessibility in mind`}</h2>
    <p>{`Blocks isn't anywhere near as accessible as it should be. We
plan on investing substantial time in making sure it's a
shining example for projects with complex user interactions.`}</p>
    <p>{`Addressing accessibility issues will always be top priority.`}</p>
    <h2>{`Integrating with data`}</h2>
    <p>{`Blocks doesn't currently have a data layer. In the future we'd
like to provide a mechanism to attach Blocks to other data sources,
whether that's a Pokémon API, a GraphQL endpoint, a CMS, or a
JSON file on GitHub.`}</p>
    <h2>{`Library for AST transforms`}</h2>
    <p>{`We'd like for Blocks to expose an AST transforms library that
consists of low-level utilities that can be used in other tools
or completely custom UIs.`}</p>
    <h2>{`Styling`}</h2>
    <p>{`Blocks is currently tightly coupled to Theme UI in the proof of
concept. However, we plan on exposing an API for other libraries
and design systems to expose a way to change styling (in addition
to `}<a parentName="p" {...{
        "href": "/docs/controls"
      }}>{`controls`}</a>{`).`}</p>
    <h2>{`What about framework X?`}</h2>
    <p>{`Ultimately, anything that speaks JSX will be able to be usable
in Blocks. The proof of concept is React specific, but we intend
on support other frameworks like Preact and Vue.`}</p>
    <h2>{`A collection of block components`}</h2>
    <p>{`Whether you're using the Blocks editor or not, we also want to
offer a collection of high-level components that can be used in
your projects.`}</p>
    <h2>{`Features, features, features`}</h2>
    <p>{`Development for Blocks is woefully incomplete at this point. There
are tons of features that still need to be added. We welcome requesting
new features as well on GitHub.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      